import React from 'react'
import Pill from './Pill'
import InternalLink from './InternalLink'
import ChevronLeftSVG from '../images/chevron-left.inline.svg'

const CategoryLink = (props) => {
  const {title,meta, icon} = props
  return(
    <InternalLink title={title} uid={meta.uid} type={meta.type} lang={meta.lang}>
      <Pill>
        {icon === 'chevron-left' &&
          <ChevronLeftSVG />
        }
        {title}
      </Pill>
    </InternalLink>
  )
}

export default CategoryLink