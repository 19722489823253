import React from 'react'
import CategoryLink from './CategoryLink'
import get from 'lodash/get'

const ListCategories = (props) => {
  const {categories} = props
  return(
    <ul className="list-categories">
      {categories.map((category, index)=>{
        const title = get(category,'node.title[0].text')
        const meta = get(category,'node._meta')
        return (<li key={index}><CategoryLink title={title} meta={meta} /></li>)
      })}
    </ul>
  )
}

export default ListCategories