import React from 'react'
import ListCategories from './ListCategories'
import CategoryLink from './CategoryLink'
import { defaultLang } from '../../prismic-configuration'
import getTranslations from '../translations'


const CategoryHeader = (props) => {
  const {title, description, categories, inspirationMeta} = props
  const lang = inspirationMeta?.lang;
  const t = getTranslations(lang)
  const allCategoriesMeta = lang ? {uid:"inspiration", type:"inspiration", lang: lang} : {uid:"inspiration", type:"inspiration"};

  return(
    <header>
      <h1 className="fs--l mb--xs">{title}</h1>
      <div className="description co--light lh--m paragraph-max-width mb--m">{description}</div>
      {categories ?
        <ListCategories categories={categories} />
      :
      <ul>
        <li><CategoryLink title={t.categories.all_categories} meta={allCategoriesMeta} icon="chevron-left" /></li>
      </ul>
      }
    </header>
  )
}

export default CategoryHeader