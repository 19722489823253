import React, { useState } from "react"
import get from "lodash/get"
import InternalLink from "../components/InternalLink"
import Img from "../components/CustomImg"
import AspectRatio from "../components/AspectRatio"
import TeaserTextOverlay from "./TeaserTextOverlay"
import { CSSTransition } from "react-transition-group"
import { defaultLang } from "../../prismic-configuration"
import getTranslations from "../translations"

const CaseTeaser = props => {
  const {
    title,
    meta,
    prismicImage,
    detailPrismicImage,
    description,
    collections,
  } = props
  const t = getTranslations(meta.lang)
  const [detailImgVisible, setDetailImgVisible] = useState(false)
  let collectionTitles = ""

  if (collections) {
    collections.forEach((collection, i) => {
      const title = get(collection, "collection.title[0].text")
      if (title) {
        collectionTitles += title
        if (collections.length - 1 !== i) {
          collectionTitles += ", "
        }
      }
    })
  }

  return (
    <div className="teaser">
      <InternalLink uid={meta.uid} type={meta.type} lang={meta.lang} title={title}>
        <header className="mb--m">
          <h3 className="fs--xs co--light tt--u mb--xs">{collectionTitles}</h3>
          <h2 className="fs--m">{title}</h2>
        </header>

        {prismicImage && (
          <div
            className="teaser-image-wrap"
            onMouseEnter={() => {
              setDetailImgVisible(true)
            }}
            onMouseLeave={() => {
              setDetailImgVisible(false)
            }}
          >
            <AspectRatio ratio="portrait">
              <Img prismicImage={prismicImage} />
              <CSSTransition
                in={detailImgVisible}
                timeout={500}
                classNames="teaser-img"
                unmountOnExit
              >
                <Img prismicImage={detailPrismicImage} />
              </CSSTransition>
            </AspectRatio>
            <div className="teaser-text-overlay case-teaser-overlay">
              <p className="fs--m lh--s"></p>
              <div className="button fs--s light">
                <div className="button-text">
                  {t.cases.explore_case}
                </div>
                <div className="button-icon">
                  <svg className="stroke" viewBox="0 0 24 24">
                    <path d="M9 18l6-6-6-6"></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        )}
      </InternalLink>
    </div>
  )
}

export default CaseTeaser
