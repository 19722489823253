import React from 'react'
import Wrapper from '../components/Wrapper'
import Masonry from 'react-masonry-css'

const CasesGrid = (props) =>{
  const {cols} = props
  return(
    <Wrapper>
      <Masonry
        breakpointCols={{
          default: 2,
          768: 1
        }}
        className="cases-grid"
        columnClassName="case-col"
      >
        {cols}
      </Masonry>
    </Wrapper>
  )
}

export default CasesGrid